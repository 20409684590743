var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('body',[_c('div',{attrs:{"id":"main-wrapper","data-layout":"vertical","data-navbarbg":"skin5","data-sidebartype":"full","data-sidebar-position":"absolute","data-header-position":"absolute","data-boxed-layout":"full"}},[_c('div',{staticClass:"dashboard-wrapper page-wrapper"},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xlg-9 col-md-12"},[_c('ValidationObserver',{ref:"simpleRules"},[_c('form',{staticClass:"form-horizontal form-material",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('div',{staticClass:"card profile-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Company Name")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"companyName","state":errors.length > 0 ? false : null,"placeholder":"Company Name"},model:{value:(_vm.companies.name),callback:function ($$v) {_vm.$set(_vm.companies, "name", $$v)},expression:"companies.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Email")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.companies.email),callback:function ($$v) {_vm.$set(_vm.companies, "email", $$v)},expression:"companies.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("About")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"About","rules":"required|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-form-textarea',{attrs:{"id":"fh-email","state":errors.length > 0 ? false : null,"placeholder":"About"},model:{value:(_vm.companies.about),callback:function ($$v) {_vm.$set(_vm.companies, "about", $$v)},expression:"companies.about"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Currency")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-form-input',{attrs:{"id":"fh-Currency","type":"Currency","state":errors.length > 0 ? false : null,"placeholder":"Currency"},model:{value:(_vm.companies.Currency),callback:function ($$v) {_vm.$set(_vm.companies, "Currency", $$v)},expression:"companies.Currency"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-img',{class:_vm.previewLogoImg.length <= 0 ? 'd-none' : null,attrs:{"thumbnail":"","fluid":"","src":_vm.previewLogoImg,"alt":"Image"}})],1),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Company Logo")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('div',{staticClass:"formcta_wrapper"},[_c('div',[_c('validation-provider',{attrs:{"name":"Company Logo","rules":"image|size:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"ImageIcon"}})],1),_c('b-form-file',{ref:"comLogInput",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onComLogoChange},model:{value:(_vm.comLogoImg),callback:function ($$v) {_vm.comLogoImg=$$v},expression:"comLogoImg"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-img',{class:_vm.previewBackImg.length <= 0 ? 'd-none' : null,attrs:{"thumbnail":"","fluid":"","src":_vm.previewBackImg,"alt":"Image"}})],1),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Company Backgound Image")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('div',{staticClass:"formcta_wrapper"},[_c('div',[_c('validation-provider',{attrs:{"name":"Company Backgound Logo","rules":"image|size:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"ImageIcon"}})],1),_c('b-form-file',{ref:"combackInput",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onComBackChange},model:{value:(_vm.comBackgroundImg),callback:function ($$v) {_vm.comBackgroundImg=$$v},expression:"comBackgroundImg"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Phone No")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Phone No","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"phoneNum","state":errors.length > 0 ? false : null,"placeholder":"Phone No"},model:{value:(_vm.companies.phone_no),callback:function ($$v) {_vm.$set(_vm.companies, "phone_no", $$v)},expression:"companies.phone_no"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Website URL")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Website URL","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"website","state":errors.length > 0 ? false : null,"placeholder":"Website URL"},model:{value:(_vm.companies.website),callback:function ($$v) {_vm.$set(_vm.companies, "website", $$v)},expression:"companies.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)]),_c('div',{staticClass:"card profile-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Meta Title")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Meta Title","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.companies.meta_title),callback:function ($$v) {_vm.$set(_vm.companies, "meta_title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companies.meta_title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Meta Description")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Meta Description","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"rows":"5"},model:{value:(_vm.companies.meta_description),callback:function ($$v) {_vm.$set(_vm.companies, "meta_description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companies.meta_description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"col-md-12 p-0"},[_vm._v("Meta Keywords")]),_c('div',{staticClass:"col-md-12 border-bottom p-0"},[_c('validation-provider',{attrs:{"name":"Keywords","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : 'is-valid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.companies.meta_keywords),callback:function ($$v) {_vm.$set(_vm.companies, "meta_keywords", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companies.meta_keywords"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-4"},[_c('div',{staticClass:"col-sm-12 pl-0 mt-10"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Submit")])],1)])])])])])],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-breadcrumb"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('h4',{staticClass:"page-title"},[_vm._v("Edit Company")])])])])}]

export { render, staticRenderFns }